export enum actions {
  VIEW_DASHBOARD = 1,
  EDIT_HOMEPAGE = 2,
  EDIT_BANNER = 3,
  NOMIC_MAP_CREATOR = 4,
}

export enum Role {
  FeedbackViewer = 'Recall Feedback Viewer',
  BannerAdmin = 'Recall::{env}::bannerAdmin',
  HomeAdmin = 'Recall::{env}::homePublisher',
  NomicMapCreator = 'Recall::{env}::nomicMapCreator',
}

export const permissions = {
  [actions.VIEW_DASHBOARD]: [Role.FeedbackViewer],
  [actions.EDIT_HOMEPAGE]: [Role.HomeAdmin],
  [actions.EDIT_BANNER]: [Role.BannerAdmin],
  [actions.NOMIC_MAP_CREATOR]: [Role.NomicMapCreator],
}

export const getPermissions = (environment: string, action: number): string => {
  const permission = permissions[action] ?? ''
  return permission.toString().replace('{env}', environment)
}
